<template>
  <v-container fill-height>
    <v-row dense>
      <v-col cols="12" class="d-flex">
        <v-spacer />
        <v-form v-model="valid" ref="form">
          <v-card max-width="500">
            <v-card-title class="justify-center pt-8">
              <span class="headline text-h5 primary--text font-weight-medium"
                >{{ cardLabel }} Your Account</span
              >
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      aut-register-name
                      v-if="isInvitedUser"
                      outlined
                      name="name"
                      v-model="name"
                      label="Name"
                      validate-on-blur
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      aut-register-username
                      name="username"
                      :disabled="isInvitedUser"
                      outlined
                      v-model="username"
                      :rules="usernameRules"
                      label="Username*"
                      validate-on-blur
                      autocomplete="username"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-if="!isInvitedUser"
                      aut-register-email
                      name="email"
                      outlined
                      v-model="email"
                      :rules="emailRules"
                      label="Email"
                      validate-on-blur
                      autocomplete="email"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field
                      aut-register-password
                      ref="password"
                      name="password"
                      outlined
                      autocomplete="new-password"
                      v-model="password"
                      :rules="passwordRules"
                      label="Password*"
                      required
                      @change="validate"
                      :type="showContent ? 'text' : 'password'"
                      :append-icon="showContent ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showContent = !showContent"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      aut-register-confirm-password
                      ref="confirmPassword"
                      name="password"
                      outlined
                      autocomplete="new-password"
                      v-model="confirmPassword"
                      :rules="confirmPasswordRules"
                      label="Confirm Password*"
                      required
                      :type="showContent2 ? 'text' : 'password'"
                      :append-icon="showContent2 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showContent2 = !showContent2"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="px-8 pt-0 pb-8">
              <v-spacer></v-spacer>
              <v-btn aut-action-register-cancel @click="cancelRegistration">
                Cancel
              </v-btn>
              <v-btn
                aut-action-register-submit
                id="aut-register"
                color="primary"
                :disabled="!valid"
                :loading="showLoader"
                @click="submitRegistration"
              >
                {{ cardLabel }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
        <v-spacer />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions } from "vuex";
import { navigateTo, rules } from "@/util.js";
const debug = require("debug")("atman.components.register"); // eslint-disable-line
export default {
  name: "RegisterPage",
  data() {
    const component = this;
    return {
      valid: false,
      name: "",
      email: "",
      cardLabel: "Register",
      emailRules: [rules.email()],
      usernameRules: [
        rules.mandatory(),
        rules.username(),
        rules.min_length({ min_length: 8 }),
      ],
      username: "",
      showContent: false,
      showContent2: false,
      showLoader: false,
      passwordRules: [
        rules.mandatory(),
        rules.min_length({ min_length: 8 }),
        // rules.max_length({ max_length: 20 }),
        // rules.has_numbers(),
        // rules.has_upper_case(),
      ],
      confirmPasswordRules: [
        rules.mandatory(),
        (val) => val == component.password || "Mismatch between passwords",
      ],
      password: "",
      confirmPassword: "",
    };
  },
  computed: {
    isInvitedUser() {
      return !!this?.$route?.query?.username;
    },
  },
  mounted() {
    this.username = this?.$route?.query?.username || "";
    this.token = this?.$route?.query?.token || "";
  },
  methods: {
    ...mapActions("user", ["register"]),
    validate() {
      this.$refs.form.validate();
    },
    cancelRegistration() {
      this.$refs.form.reset();
      navigateTo(this.$router, "/login");
    },
    async submitRegistration() {
      const component = this;
      component.showLoader = true;
      const payload = {};
      if (component.name) {
        payload.name = component.name;
      }
      if (component.username) {
        payload.username = component.username;
      }
      if (component.password) {
        payload.password = component.password;
      }
      if (component.email) {
        payload["e-mail"] = component.email;
      }
      if (component.token) {
        payload.token = component.token;
      }
      try {
        await component.register(payload);
        component.$refs.form.reset();
        component.displaySuccessMessage("Registration Successful. ");
        navigateTo(this.$router, "/login");
      } catch (e) {
        debug("registration failed", e);
      } finally {
        component.showLoader = false;
      }
    },
  },
};
</script>
